interface Environment {
  isDev: boolean
  backendUrl: string
  backendGraphqlUrl: string
}

const env: Environment = {
  isDev: process.env.NODE_ENV === 'development',
  backendUrl: process.env.BACKEND_URL || 'http://localhost:8090',
  backendGraphqlUrl: process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_URL || 'http://localhost:8090/graphql'
}

export default env
