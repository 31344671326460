import { Vault } from './types'

export class SimpleVault implements Vault {
  private readonly storage: Map<string, any> = new Map()

  setItem<T = any>(key: string, value: T): T {
    this.storage.set(key, value)
    return value
  }

  getItem<T = any>(key: string): T | void {
    return this.storage.get(key)
  }
}
