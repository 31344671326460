import { gql } from '@apollo/client/core'

export const typeDefs = gql`
  type Coordinate {
    longitude: String!
    latitude: String!
  }

  type Settlement {
    id: String!
    name: String!
    slug: String!
    gps: Coordinate
  }

  ## Questionnaire
  enum QuestionType {
    CHOICE
    ANSWER
  }

  type QuestionChoiceOption {
    no: Int!
    option: String!
  }

  type Question {
    no: Int!
    question: String!
    type: QuestionType!
    choices: [QuestionChoiceOption!]!
  }

  type PresentableSight {
    lang: LANG!
    name: String!
    settlementName: String!
    slug: String!
    description: String!
    coverImage: String
    warmUpQuestions: [Question!]
    questionnaire: [Question!]
    nextSightId: String
  }

  enum LANG {
    en
    hu
  }

  type Query {
    getPresentableSight(settlemeSlug: String!, sightSlug: String!, lang: LANG): PresentableSight!
  }
`
